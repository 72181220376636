header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #70d09f;
  padding: 5px;
}

header > * {  
  background-color: #70d09f;
}

.Nav-link > h1 {
  font-size: 26px;
  margin: 0;
}

nav {
  display: flex;
}

.Nav-link {
  background-color:  #70d09f;
  color: black;
  text-decoration: none;
  margin: 10px;
}

.login-signup {
  font-size: 14px;
  height: 100%;
}

@media screen and (min-width: 440px) {
  header > h1 {
    font-size: 24px;
    margin: 0;
  }

  .login-signup {
    font-size: 22px;
    height: 100%;
  }
}