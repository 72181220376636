@import url(https://fonts.googleapis.com/css?family=Ubuntu);
header {
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background-color: #70d09f;
  padding: 5px;
}

header > * {  
  background-color: #70d09f;
}

.Nav-link > h1 {
  font-size: 26px;
  margin: 0;
}

nav {
  display: -webkit-flex;
  display: flex;
}

.Nav-link {
  background-color:  #70d09f;
  color: black;
  text-decoration: none;
  margin: 10px;
}

.login-signup {
  font-size: 14px;
  height: 100%;
}

@media screen and (min-width: 440px) {
  header > h1 {
    font-size: 24px;
    margin: 0;
  }

  .login-signup {
    font-size: 22px;
    height: 100%;
  }
}
label {
    padding-right: 10px;
    font-family: "Comic Sans MS", cursive, sans-serif;
}

input {
    margin: 5px;
}
button {
    background-color: #70a1d0;
    border: 1px solid #70a1d0;
    border-radius: 5px;
    font-size: 16px;
}
* {
    text-align: center;
}

.login-signup-form {
  width: 300px;
  padding: 10px 15px;
  border-radius: 10px;
  background-color: #70d09f;
}

.login-signup-form > * {
  background-color: #70d09f;
  margin-bottom: 20px;
}

.login-signup-form > p {
  font-size: 20px;
}

.login-signup-form > h2 {
  font-size: 28px;
}

.form-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.form-footer-button {
  width: 75px;
  height: 30px;
  margin: 20px 0px 0px;
}

.form-footer-link {
  margin-top: 20px;
}
.words-list-container {
  padding: 5px;
  background-color: #70d09f;
  border-radius: 5px;
  margin-top: 30px;
}

.words-list-header {
  font-size: 24px;
}

.words-list-ul {
  max-height: 425px;
  padding: 0 10px 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  list-style: none;
  overflow-y: scroll;
}

.words-list-item {
  background-color: #d07071;
  width: 200px;
  margin: 5px 0;
  padding: 5px 0;
  border-radius: 5px;
}

.words-list-score {
  display: block;
  font-size: 20px;
  margin-bottom: 10px;
}

.word-span {
  display: block;
}

.original {
  font-size: 22px;
  margin: 15px 0;
}

.translation {
  font-size: 18px;
  margin: 10px 0 10px;
}
#dashboard-container {
  -webkit-justify-content: start;
          justify-content: start;
}

.dashboard-header {
  margin: 25px 0 0;
  font-size: 36px;
}

.dashboard-language {
  margin: 30px;
}

.dashboard-welcome {
  margin-bottom: 15px;
}

.practice-link {
  text-decoration: none;
  color: black;
  padding: 5px 10px;
  background-color: #70a1d0;
  border: 1px solid #70a1d0;
  border-radius: 5px;
  font-size: 20px;
}

.practice-link:hover {
  background-color: lightgray;
}


.learning-route-container {
  padding: 10px;
  height: 400px;
  max-width: 80%;
  background-color: #70d09f;
  border-radius: 10px;
}

#learning-route-main {
  height: 380px;
  max-width: 800px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.learning-route-header {
  margin: 10px;
}

.learning-route-original {
  margin: 15px;
  font-size: 24px;
}

.learning-route-form {
  margin: 15px 0;
}

.learning-route-label {
  font-size: 18px;
}

.learning-route-total {
  font-size: 22px;
}

@media screen and (min-width: 464px) {
  .learning-route-container {
    height: 380px;
  }

  #learning-route-main {
    height: 360px;
  }
}

@media screen and (min-width: 850px) {
  .learning-route-container {
    height: 320px;
  }

  #learning-route-main {
    height: 300px;
  }
}

* {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(214, 107, 107);
}

main {
  padding: 60px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

:root {
  --font-size: calc(10px + 1vmin);
}

